import * as React from 'react';
import styled from 'styled-components';
// import {useStaticQuery, graphql} from 'gatsby';
// import {GatsbyImage, getImage} from 'gatsby-plugin-image';
// import Gallery from '@browniebroke/gatsby-image-gallery';
import {Link} from 'gatsby';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['22px', null, null, '25px'],
  textAlign: 'left',
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Item = styled.li`
  text-align: left;
`;
// const useGallery = () => {
//   const data = useStaticQuery(graphql`
//     query GalleryImages {
//       images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
//         nodes {
//           Key
//           localFile {
//             childImageSharp {
//               thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
//               full: gatsbyImageData(layout: FULL_WIDTH)
//             }
//           }
//         }
//       }
//     }
//   `);
//   return data.images.nodes.map(node => node.localFile.childImageSharp);
// };

const PackingPage = () => {
  return (
    <Page>
      <ContentWrapper>
        <TitleWrapper>
          <Title text={'Packing'} />
        </TitleWrapper>
        <Box m={'0 auto'} maxWidth={'1000px'}>
          <Content>
            It is very important that you pack your suitcase with the appropriate apparel. Remember, you can always take
            layers off, so it’s better to "over pack".
          </Content>
          <Content>
            The weather in Beaver Creek this time of year can be unpredictable. The temperature during the day may
            fluctuate between 5 and 60 degrees! So Please check out the <Link to={'/weather'}>Weather Page</Link> for
            more information.
          </Content>
          <SubTitle>Suitcase Must-Haves</SubTitle>
          <Content>
            <List>
              <Item>Warm socks</Item>
              <Item>Practical shoes- waterproof boots</Item>
              <Item>Long sleeve shirts</Item>
              <Item>Comfortable pants/thermals</Item>
              <Item>Sunscreen (at least SPF 30)</Item>
              <Item>Sunglasses</Item>
              <Item>Hand lotion</Item>
              <Item>Lip balm with SPF</Item>
              <Item>A warm hat, gloves</Item>
              <Item>A warm and lined winter coat</Item>
            </List>
          </Content>
          <SubTitle>Dress Codes</SubTitle>
          <Content textAlign={'left'}>
            <b>Thursday Night</b> - Colorado Casual
            <p>dressy casual, Fun winter sweaters encouraged</p>
            <br />
            <b>Friday Night Rehearsal Dinner</b> - Mountain Elegant
            <p>
              Cocktail attire, there is a sleigh ride to Zach’s cabin, therefore please take that into account when
              choosing your footwear
            </p>
            <br />
            <b>Saturday Night Wedding</b> - Black Tie Optional
            <p>
              The ceremony will be outside snow or shine (heaters, umbrellas, and blankets will be provided if necessary
            </p>
          </Content>
        </Box>
      </ContentWrapper>
    </Page>
  );
};

export default PackingPage;
